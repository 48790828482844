import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "../../conversational-ai-platform/blog/helpdesk-problems-solutions";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image = require("../../../assets/img/blogs/badoo_blog_header.png");
const section_1 = require("../../../assets/img/blogs/badoo_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/badoo_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/badoo_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/badoo_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/badoo_blog_img_5.png");
const section_6 = require("../../../assets/img/blogs/badoo_blog_img_6.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");

const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="How to automate employee onboarding and offboarding process using BambooHR and Azure AD"
        description="Use Workativ Automate to manage employee lifecycle through BambooHR and Azure AD integrations seamlessly. No manual steps for IT guys, rapid implementation!"
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container additionalClass={"automate-blog-nav-bar"}>
        <Layout
          backgroundColor={"bg_header_automation"}
          logoFor="AUTOMATE"
          product="AUTOMATE"
        >
          <section className="w-100 float-left blog_top_header_upt badoo_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img ${
                  isMobile ? "text-align-center" : "float-left"
                }`}
              >
                {isMobile ? (
                  <>
                    <h1 className="font-page-header-home-blog">
                      How to automate employee onboarding and offboarding
                      process using BambooHR and Azure AD
                    </h1>
                  </>
                ) : (
                  <>
                    <h1 className="font-page-header-home-blog">
                      How to automate employee
                      <br /> onboarding and offboarding
                      <br /> process using BambooHR
                      <br /> and Azure AD
                    </h1>
                  </>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}
          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm
            product="automate"
            content="Start on our free plan, and scale up as you grow."
            AutomateCTA
            automateContent
            autoamteButton
            automateName
            isFooterForm={true}
          />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. Why implementing HR workflow automation is a challenge for
                  IT teams?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. How do manual workflows vs. automated workflows impact the
                  IT experience?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. simplest implementation methods for employee lifecycle
                  management automation using BambooHR to Active Directory or
                  Azure AD integration with Workativ Automate
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. What are the benefits of BambooHR X Azure AD integrations
                  for HR workflow automation with Workativ Automate?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. Conclusion
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Imagine an everyday work scenario across IT operations. It
                reflects the same old activities 一 ensuring top-level
                operational efficiency across the IT environment, responding to
                employee requests, and so on and so forth. The agony of
                overseeing various aspects of ITSM operations sometimes goes
                overhead 一 a probable reason for ITSM fatigue and disengaged
                employees, not to mention a slump in employee experience
                altogether.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Over the years, employee-centric IT has emerged as a
                value-addition to enterprise operations. An{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/ai-it-service-management">
                  AI-powered ITSM
                </a>{" "}
                that improves ITSM capabilities to ensure improvement of service
                delivery and employee opportunities is considered an employee
                enabler.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Adding to this business expectation,{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/top-itsm-trends-2023">
                  employee experience management is one of
                </a>{" "}
                the leading aspects of ITSM trends, meaning employee experience
                matters for an organization to thrive.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In the present scenario of a hybrid work setting, where employee
                expectations are volatile, an{" "}
                <a href="https://workativ.com/conversational-ai-platform/impact-of-AI">
                  AI-driven ITSM can provide exemplified service delivery to
                  help look into what matters most for employee issues and what
                  satisfies employee
                </a>
                &nbsp;expectations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                One of the core areas of ITSM to facilitate employee service
                delivery without friction and facilitate employee experience is
                to help with HR workflow automation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Streamlining{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/hr-help-desk-guide">
                  HR operations with AI-powered workflow automation
                </a>
                &nbsp;brings down ticket volumes. It promotes IT self-service,
                making IT people more capable of managing strategic business
                problems and getting peace of mind.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Our article is a presentation of how organizations can help
                alleviate workloads for ITSM professionals in implementing HR
                workflow automation for expedited enterprise service management.
                Let’s find out.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Why implementing HR workflow automation is a challenge for IT
                teams?
              </h2>

              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="employee lifecycle management or hr workflow automation challenge"
              ></img>
              <p class="font-section-normal-text-testimonials line-height-18">
                IT professionals tend to experience automation and integration
                challenges in their workflows. There are many valid reasons for
                them that delay automation implementation and impact employee
                expectations.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials-medium color-black">
                  Scarcity of appropriate documentation
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                When automation and integrations are being developed in-house,
                addressing any system breakdown is a very obvious challenge for
                the team handling the project for the first time. It is even
                more exhaustive if project leaders working on the automation
                project leave, making the current IT teams clueless about where
                to start.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                A no-code solution, for example,{" "}
                <a href="https://workativ.com/workflow-automation-platform">
                  Workativ Automate
                </a>{" "}
                is easy to use to address new issues for new IT teams. It allows
                full visibility into workflows in action with specific apps and
                manages processes without prior knowledge of handling the
                system.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials-medium color-black">
                  Waterfall approach for automation
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                The waterfall model is averse to scaling. Companies that follow
                this approach may wrap up the projects in a defined time but
                cannot return to the previous phases to address issues if any
                problem arises.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                A no-code/low-code platform built on Workativ provides an agile
                methodology with a continuous feedback loop to improve
                automation functionalities in workflows.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials-medium color-black">
                  Aesthetic challenges of an automation tool
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                Not all ITSM tools are equal. Their aesthetic aspects are
                different. As a result, organizations need to have an IT team
                with extensive programming expertise to work across particular
                ITSM platforms when it comes to setting up the UI/UX features or
                functionalities for custom solutions. But, limited skilled IT
                professionals can delay the automation project.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                It also requires IT people to adeptly work with the Azure AD
                environment, which may be a constraint for organizations if they
                need to hire new resources.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                Workativ is a no-code platform that does not require a steep
                learning curve to implement workflow automation, making it a
                breeze for IT guys to implement HR workflow automation in a few
                minutes.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials-medium color-black">
                  Data governance
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                Data sanctity is integral to any organization to ensure
                top-level privacy policy and data governance at the workplace.
                The risk of data leakage increases with the adoption of
                third-party services.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                However, Workativ provides a better approach to ensuring data
                governance by providing its cloud-based offering with robust
                HIPPA and GDPR compliance.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                With Workativ Automate, HR workflow automation is easy for IT
                professionals, and thus, adoption can be made easy.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How do manual workflows vs. automated workflows impact the IT
                experience?
              </h2>

              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="Manual and Automated employee lifecycle management"
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                IT operations are critical and overwhelming for IT teams. With
                critical responsibilities involving setting up, configuring, and
                deploying Azure Active Directory user provisioning, it is a huge
                manual effort for them.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Simultaneously, creating HR workflow automation with Azure AD
                means process errors and configuration challenges should the IT
                team possess limited technical know-how.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Also, manual workflow management is a concurrent process that
                requires the active participation of IT professionals all the
                time. As a result, if anyone goes out of operations from the
                loop, the user experience can be impacted.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example,
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Azure AD user provisioning involves multiple steps when managed
                manually,
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span>1. </span>
                  <span>
                    Employee status update (the employee added or removed, etc.)
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span>2. </span>
                  <span>Event created in BambooHR</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span>3. </span>
                  <span>
                    A ticket is created by HR into the ITSM system for user
                    provisioning in Azure AD
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span>4. </span>
                  <span>An ITSM platform gets a ticket</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span>5. </span>
                  <span>Ticket in a queue for the system admin</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span>6. </span>
                  <span>An account is created in Azure AD</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span>7. </span>
                  <span>
                    System admin notifies HR and manager through emails
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span>8. </span>
                  <span>Employee lifecycle for HR flow completed</span>
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Now, observe how from HR to IT people are involved in the
                processes. It is an ongoing process for HR and IT people in a
                manual workflow. If they miss their responsibilities,
                communication will remain incomplete, and the process will be
                delayed.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                But it is a completely seamless experience for IT people when
                they use a{" "}
                <a href="https://workativ.com/workflow-automation-platform/integrations/microsoft-azure-ad">
                  cloud HR to Active Directory or Azure AD integration platform.
                </a>
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="Simplified processes to manage for IT with BambooHR and Azure AD integrations"
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                All they need is to create the workflow automation just once and
                forget, and never involve them in overseeing the activities such
                as handling user provisioning tickets, creating an Azure AD
                account, and then writing emails back to HR and managers.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Conversely, HR teams can free themselves from managing user
                provisioning in the ITSM platform.
              </p>

              <p className="font-section-normal-text-testimonials-medium line-height-18">
                With a solution like Workativ Automate, there are fewer steps to
                configure for IT people as opposed to what it takes to create
                accounts directly in Azure AD and then integrate with cloud HR
                apps such as BambooHR, SAP SuccessFactors, or Workday to create
                an automated Azure AD user provision workflows.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Traditionally, when your IT people work with Azure AD to sync
                with cloud HR apps to create automated user provisioning for HR
                workflow automation, they must employ multiple steps carefully.
                Even a small wrong configuration step can delay automation
                deployment.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                There are as many as seven{" "}
                <a href="https://learn.microsoft.com/en-us/azure/active-directory/app-provisioning/plan-cloud-hr-provision">
                  HR provisioning app deployment topologies in Azure AD.
                </a>{" "}
                These are quite intricate and hard to manage.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                By removing all these extra steps and manual efforts for your IT
                people, Workativ Automate makes deploying Azure AD user
                provision easy.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It means Workativ Automate delivers dual benefits with its
                capability to automate Azure AD user provision.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>1. </span>
                  <span>
                    Workativ Automate provides flexible and convenient Azure AD
                    configuration that is simple to manage and deploy with no
                    high-level programming expertise.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>2. </span>
                  <span>
                    Post-deployment, Workativ Automate removes human oversight
                    from IT people and HR to completely automate user
                    provisioning that helps with onboarding and offboarding
                    automation and employee updates.
                  </span>
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                3 simplest implementation methods for employee lifecycle
                management automation using BambooHR to Active Directory or
                Azure AD integration with Workativ Automate
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s discover how easy it is to implement workflow automation
                for employee lifecycle management using BambooHR to Azure AD
                integration with Automate platform.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Employee onboarding using Azure AD integrations
              </h3>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="employee onboarding automation using BambooHR and Azure AD integration with Automate "
              ></img>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>1. </span>
                  <span>
                    Access Automate{" "}
                    <a href="https://sit-automate.workativ.ai/">
                      Workflow Builder
                    </a>
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>2. </span>
                  <span>
                    Create a workflow by downloading it from a marketplace
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>3. </span>
                  <span>
                    Select the applications, i.e., BambooHR, Azure AD, and
                    Outlook
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>4. </span>
                  <span>Click on View Workflows</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>5. </span>
                  <span>
                    Find and click Employee Onboarding workflows, among other
                    workflows
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>6. </span>
                  <span>
                    When a new employee is added to BambooHR, a workflow starts
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>7. </span>
                  <span>
                    A workflow has multiple steps, which involve adding an Azure
                    AD user, updating a user, assigning a license to a user,
                    assigning a manager, and sending an email
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>8. </span>
                  <span>Download the workflow automation</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>9. </span>
                  <span>It is stored in the Draft file</span>
                </li>

                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>10. </span>
                  <span>Now, edit the app workflow</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>11. </span>
                  <span>
                    For a new employee trigger, BambooHR is already added to
                  </span>
                  flow
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>12. </span>
                  <span>Add a new employee account</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>13. </span>
                  <span>Select connect application, BambooHR</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>14. </span>
                  <span>Click on Test connection and click on Save</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>15. </span>
                  <span>Now, configure the Azure AD user flow</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>16. </span>
                  <span>Connect with Azure AD</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>17. </span>
                  <span>Update predefined Input fields if you wish</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>18. </span>
                  <span>Click on Save</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>19. </span>
                  <span>
                    19. Set the timer to allow Azure AD to fetch updates and
                    Save
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>20. </span>
                  <span>Update users now</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>21. </span>
                  <span>Connect application for Azure AD</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>22. </span>
                  <span>
                    Update users by providing details in the ‘Action Input’
                    section and save
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>23. </span>
                  <span>Assign a license to a user</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>24. </span>
                  <span>Connect to the Azure AD account</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>25. </span>
                  <span>
                    In the Action Input field, provide the license name if you
                    created it for Azure AD
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>26. </span>
                  <span>Save it</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>27. </span>
                  <span>Assign a manager for approval</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>28. </span>
                  <span>
                    Select Azure AD for manager details from the Action Input
                    field
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>29. </span>
                  <span>
                    Create a step to send an email to HR once onboarding is
                    completed
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>30. </span>
                  <span>
                    Connect with an Outlook account and provide an HR email
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>31. </span>
                  <span>Add a CC email</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>32. </span>
                  <span>Save it</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>33. </span>
                  <span>ctivate the workflow</span>
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is that easy. So, when an employee is added to BambooHR, a
                workflow trigger will be activated to complete the action of
                Azure AD provisioning and help execute the onboarding process on
                behalf of the HR executive.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Employee offboarding with Active Directory sync
              </h3>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="employee offboarding automation using BambooHR and Azure AD integration with Automate  "
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                The steps of implementing Azure AD integrations for employee
                offboarding are the same as employee onboarding except for two
                or three processes.
              </p>
              <ul class="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>1. </span>
                  <span>Access Automate Workflow Builder</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>2. </span>
                  <span>
                    Create a workflow by downloading it from a marketplace
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>3. </span>
                  <span>
                    Select the applications, i.e., BambooHR, Azure AD, and
                    Outlook
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>4. </span>
                  <span>Click on View Workflows</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>5. </span>
                  <span>
                    Find and click Employee Offboarding workflows, among other
                    workflows
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>6. </span>
                  <span>
                    When an employee leaves, the details are added to BambooHR,
                    and a workflow starts
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>7. </span>
                  <span>Download the workflow automation</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>8. </span>
                  <span>Add the employee details to the BambooHR account</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>9. </span>
                  <span>
                    Employee history account is checked, and the workflow will
                    be triggered
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>10. </span>
                  <span>
                    In this workflow, there are two extra steps for approval
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>11. </span>
                  <span>
                    If approved, the user license will be removed, their account
                    will be suspended, and HR or the manager will be notified
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>12. </span>
                  <span>Apply the condition for "If Approved"</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>13. </span>
                  <span>Fill 'Approval' details</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>14. </span>
                  <span>Remove the user's license</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>15. </span>
                  <span>Suspend the user account</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>16. </span>
                  <span>
                    Provide an email address to notify a particular manager or
                    HR for that specific user
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>17. </span>
                  <span>
                    If you disapprove or reject, set up your conditions by
                    removing the workflow trigger and sending a confirmation
                    email to HR or the manager
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>18. </span>
                  <span>Activate the workflow</span>
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18">
                So, your BambooHR and Azure AD onboarding workflow is ready to
                go.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Employee update in Azure AD
              </h3>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="employee update automation using BambooHR and Azure AD integration with Automate "
              ></img>

              <p className="font-section-normal-text-testimonials line-height-18">
                When a new employee status comes up in BambooHR, a new status
                can be updated in the Azure AD Identity management system using
                the Workativ Automate integration platform.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The steps are pretty much similar to the previous two workflows.
              </p>
              <ul class="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>1. </span>
                  <span>
                    Create a new workflow for employee updates in Azure AD
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>2. </span>
                  <span>
                    Choose your applications, i.e., BambooHR, Azure AD
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>3. </span>
                  <span>
                    Find "Employee Update" workflow templates among other
                    templates and choose it
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>4. </span>
                  <span>Download the workflow</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>5. </span>
                  <span>
                    Similarly, like other workflows, connect the BambooHR
                    account and save it
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>6. </span>
                  <span>
                    Modify the Azure AD account by providing details for the
                    appropriate fields
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>7. </span>
                  <span>It is all done</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>8. </span>
                  <span>Activate the workflow</span>
                </li>
              </ul>

              <h3 className="font-section-sub-header-small-home">
                Watch our tutorial video
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                To learn more about implementing successful workflows for
                employee onboarding/offboarding and employee updates,{" "}
                <a
                  href="https://www.youtube.com/watch?v=zWyg_1kVuAA"
                  target="_blank"
                >
                  watch our YouTube video now.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What are the benefits of BambooHR X Azure AD integrations for HR
                workflow automation with Workativ Automate?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                As you observe through the steps for workflow implementation, it
                is an absolute no-brainer.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                No coding or exerting complicated programming skills here.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ Automate is a highly useful automation platform for
                employee onboarding, onboarding, and employee updates. It does
                not unleash extra effort for your IT people to work with the
                platform and implement workflow automation. The platform
                delivers ample employee benefits to improve IT productivity and
                employee experience.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials-medium color-black">
                  Fast deployment, zero waste of productivity
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                While creating manually for Azure AD accounts per user may take
                several hours. But, with workflows being implemented with
                Workativ Automate, an action for employee onboarding/offboarding
                or user updates takes place in a few minutes.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                Organizations can have a faster deployment of workflows and
                reduce productivity loads, meaning time savings and cost
                efficiency for IT leaders.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials-medium color-black">
                  Effective employee life cycle management
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                Workativ Automate flexibly helps you manage the employee
                lifecycle by allowing you to create incidents in ITSM platforms
                and notify the right person to execute the action.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                For example, if a new employee is onboarded, an ITSM can send a
                notification for Outlook or Slack to allow the IT asset team to
                allocate appropriate resources.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials-medium color-black">
                  Reduction in ticket volumes
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                Yes, this is a huge respite for ITSM managers as they are less
                likely to spend time addressing tickets in queues for employee
                lifecycle management, cutting off the need to connect with HR
                and then employees back and forth for every update.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                All happens silently in the backend with Workativ Automate
                workflow automation.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials-medium color-black">
                  Seamless regulatory compliance
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                In many instances, employee offboarding does not complete the
                suspension of licensing in Azure AD, inadvertently allowing for
                misusing enterprise data.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                Workativ Automate helps comply with organizational laws and
                rules by seamlessly automating the deprovisioning and suspending
                of user accounts from the Azure AD.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials-medium color-black">
                  Enhanced productivity
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                With Workativ Automate, there are no longer back-and-forth IT
                maneuvers in the Azure AD, which frees up more time for IT
                people from manual work and allows them to work more on
                strategic aspects of the business.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Employee lifecycle management is critical for every organization
                to improve operational efficiency while offering enhanced
                employee experience and facilitating organizational growth.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is where the HR cloud app and Azure AD integrations make it
                easy to build end-to-end employee lifecycle management
                processes.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/workflow-automation-platform/integrations/bamboohr+microsoft-azure-ad">
                  Workativ is a no-code platform,
                </a>{" "}
                and workflow implementation is just easy with this platform. It
                eliminates all the intricate manual steps for Azure AD account
                creation and helps speed up employee onboarding/offboarding and
                employee updates.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to simplify employee lifecycle management through
                BambooHR and Azure AD integrations and give your IT people less
                headache, Workativ Automate is your answer.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To book a demo with our workflow automation expert,{" "}
                <a href="https://outlook.office365.com/owa/calendar/Bookademowithourworkflowautomationexpert@workativ.com/bookings/">
                  set up your preferred discovery call today.
                </a>
              </p>
            </div>

            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. Why implementing HR workflow automation is a challenge
                    for IT teams?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. How do manual workflows vs. automated workflows impact
                    the IT experience?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. simplest implementation methods for employee lifecycle
                    management automation using BambooHR to Active Directory or
                    Azure AD integration with Workativ Automate
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. What are the benefits of BambooHR X Azure AD integrations
                    for HR workflow automation with Workativ Automate?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. Conclusion
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
          <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Imagine an everyday work scenario across IT operations. It
                reflects the same old activities 一 ensuring top-level
                operational efficiency across the IT environment, responding to
                employee requests, and so on and so forth. The agony of
                overseeing various aspects of ITSM operations sometimes goes
                overhead 一 a probable reason for ITSM fatigue and disengaged
                employees, not to mention a slump in employee experience
                altogether.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Over the years, employee-centric IT has emerged as a
                value-addition to enterprise operations. An{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/ai-it-service-management">
                  AI-powered ITSM
                </a>{" "}
                that improves ITSM capabilities to ensure improvement of service
                delivery and employee opportunities is considered an employee
                enabler.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Adding to this business expectation,{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/top-itsm-trends-2023">
                  employee experience management is one of
                </a>{" "}
                the leading aspects of ITSM trends, meaning employee experience
                matters for an organization to thrive.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In the present scenario of a hybrid work setting, where employee
                expectations are volatile, an{" "}
                <a href="https://workativ.com/conversational-ai-platform/impact-of-AI">
                  AI-driven ITSM can provide exemplified service delivery to
                  help look into what matters most for employee issues and what
                  satisfies employee
                </a>
                &nbsp;expectations.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                One of the core areas of ITSM to facilitate employee service
                delivery without friction and facilitate employee experience is
                to help with HR workflow automation.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Streamlining{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/hr-help-desk-guide">
                  HR operations with AI-powered workflow automation
                </a>
                &nbsp;brings down ticket volumes. It promotes IT self-service,
                making IT people more capable of managing strategic business
                problems and getting peace of mind.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Our article is a presentation of how organizations can help
                alleviate workloads for ITSM professionals in implementing HR
                workflow automation for expedited enterprise service management.
                Let’s find out.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Why implementing HR workflow automation is a challenge for IT
                teams?
              </h2>

              <img
                src={section_1}
                className="blog_image_top_bt"
                alt="employee lifecycle management or hr workflow automation challenge"
              ></img>
              <p class="font-section-normal-text-testimonials line-height-18">
                IT professionals tend to experience automation and integration
                challenges in their workflows. There are many valid reasons for
                them that delay automation implementation and impact employee
                expectations.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials-medium color-black">
                  Scarcity of appropriate documentation
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                When automation and integrations are being developed in-house,
                addressing any system breakdown is a very obvious challenge for
                the team handling the project for the first time. It is even
                more exhaustive if project leaders working on the automation
                project leave, making the current IT teams clueless about where
                to start.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                A no-code solution, for example,{" "}
                <a href="https://workativ.com/workflow-automation-platform">
                  Workativ Automate
                </a>{" "}
                is easy to use to address new issues for new IT teams. It allows
                full visibility into workflows in action with specific apps and
                manages processes without prior knowledge of handling the
                system.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials-medium color-black">
                  Waterfall approach for automation
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                The waterfall model is averse to scaling. Companies that follow
                this approach may wrap up the projects in a defined time but
                cannot return to the previous phases to address issues if any
                problem arises.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                A no-code/low-code platform built on Workativ provides an agile
                methodology with a continuous feedback loop to improve
                automation functionalities in workflows.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials-medium color-black">
                  Aesthetic challenges of an automation tool
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                Not all ITSM tools are equal. Their aesthetic aspects are
                different. As a result, organizations need to have an IT team
                with extensive programming expertise to work across particular
                ITSM platforms when it comes to setting up the UI/UX features or
                functionalities for custom solutions. But, limited skilled IT
                professionals can delay the automation project.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                It also requires IT people to adeptly work with the Azure AD
                environment, which may be a constraint for organizations if they
                need to hire new resources.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                Workativ is a no-code platform that does not require a steep
                learning curve to implement workflow automation, making it a
                breeze for IT guys to implement HR workflow automation in a few
                minutes.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials-medium color-black">
                  Data governance
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                Data sanctity is integral to any organization to ensure
                top-level privacy policy and data governance at the workplace.
                The risk of data leakage increases with the adoption of
                third-party services.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                However, Workativ provides a better approach to ensuring data
                governance by providing its cloud-based offering with robust
                HIPPA and GDPR compliance.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                With Workativ Automate, HR workflow automation is easy for IT
                professionals, and thus, adoption can be made easy.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How do manual workflows vs. automated workflows impact the IT
                experience?
              </h2>

              <img
                src={section_2}
                className="blog_image_top_bt"
                alt="Manual and Automated employee lifecycle management"
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                IT operations are critical and overwhelming for IT teams. With
                critical responsibilities involving setting up, configuring, and
                deploying Azure Active Directory user provisioning, it is a huge
                manual effort for them.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Simultaneously, creating HR workflow automation with Azure AD
                means process errors and configuration challenges should the IT
                team possess limited technical know-how.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Also, manual workflow management is a concurrent process that
                requires the active participation of IT professionals all the
                time. As a result, if anyone goes out of operations from the
                loop, the user experience can be impacted.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                For example,
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Azure AD user provisioning involves multiple steps when managed
                manually,
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span>1. </span>
                  <span>
                    Employee status update (the employee added or removed, etc.)
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span>2. </span>
                  <span>Event created in BambooHR</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span>3. </span>
                  <span>
                    A ticket is created by HR into the ITSM system for user
                    provisioning in Azure AD
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span>4. </span>
                  <span>An ITSM platform gets a ticket</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span>5. </span>
                  <span>Ticket in a queue for the system admin</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span>6. </span>
                  <span>An account is created in Azure AD</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span>7. </span>
                  <span>
                    System admin notifies HR and manager through emails
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter color-black">
                  <span>8. </span>
                  <span>Employee lifecycle for HR flow completed</span>
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                Now, observe how from HR to IT people are involved in the
                processes. It is an ongoing process for HR and IT people in a
                manual workflow. If they miss their responsibilities,
                communication will remain incomplete, and the process will be
                delayed.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 mb-0">
                But it is a completely seamless experience for IT people when
                they use a{" "}
                <a href="https://workativ.com/workflow-automation-platform/integrations/microsoft-azure-ad">
                  cloud HR to Active Directory or Azure AD integration platform.
                </a>
              </p>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="Simplified processes to manage for IT with BambooHR and Azure AD integrations"
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                All they need is to create the workflow automation just once and
                forget, and never involve them in overseeing the activities such
                as handling user provisioning tickets, creating an Azure AD
                account, and then writing emails back to HR and managers.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Conversely, HR teams can free themselves from managing user
                provisioning in the ITSM platform.
              </p>

              <p className="font-section-normal-text-testimonials-medium line-height-18">
                With a solution like Workativ Automate, there are fewer steps to
                configure for IT people as opposed to what it takes to create
                accounts directly in Azure AD and then integrate with cloud HR
                apps such as BambooHR, SAP SuccessFactors, or Workday to create
                an automated Azure AD user provision workflows.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Traditionally, when your IT people work with Azure AD to sync
                with cloud HR apps to create automated user provisioning for HR
                workflow automation, they must employ multiple steps carefully.
                Even a small wrong configuration step can delay automation
                deployment.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                There are as many as seven{" "}
                <a href="https://learn.microsoft.com/en-us/azure/active-directory/app-provisioning/plan-cloud-hr-provision">
                  HR provisioning app deployment topologies in Azure AD.
                </a>{" "}
                These are quite intricate and hard to manage.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                By removing all these extra steps and manual efforts for your IT
                people, Workativ Automate makes deploying Azure AD user
                provision easy.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It means Workativ Automate delivers dual benefits with its
                capability to automate Azure AD user provision.
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>1. </span>
                  <span>
                    Workativ Automate provides flexible and convenient Azure AD
                    configuration that is simple to manage and deploy with no
                    high-level programming expertise.
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>2. </span>
                  <span>
                    Post-deployment, Workativ Automate removes human oversight
                    from IT people and HR to completely automate user
                    provisioning that helps with onboarding and offboarding
                    automation and employee updates.
                  </span>
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                3 simplest implementation methods for employee lifecycle
                management automation using BambooHR to Active Directory or
                Azure AD integration with Workativ Automate
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s discover how easy it is to implement workflow automation
                for employee lifecycle management using BambooHR to Azure AD
                integration with Automate platform.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Employee onboarding using Azure AD integrations
              </h3>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="employee onboarding automation using BambooHR and Azure AD integration with Automate "
              ></img>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>1. </span>
                  <span>
                    Access Automate{" "}
                    <a href="https://sit-automate.workativ.ai/">
                      Workflow Builder
                    </a>
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>2. </span>
                  <span>
                    Create a workflow by downloading it from a marketplace
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>3. </span>
                  <span>
                    Select the applications, i.e., BambooHR, Azure AD, and
                    Outlook
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>4. </span>
                  <span>Click on View Workflows</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>5. </span>
                  <span>
                    Find and click Employee Onboarding workflows, among other
                    workflows
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>6. </span>
                  <span>
                    When a new employee is added to BambooHR, a workflow starts
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>7. </span>
                  <span>
                    A workflow has multiple steps, which involve adding an Azure
                    AD user, updating a user, assigning a license to a user,
                    assigning a manager, and sending an email
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>8. </span>
                  <span>Download the workflow automation</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>9. </span>
                  <span>It is stored in the Draft file</span>
                </li>

                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>10. </span>
                  <span>Now, edit the app workflow</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>11. </span>
                  <span>
                    For a new employee trigger, BambooHR is already added to
                  </span>
                  flow
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>12. </span>
                  <span>Add a new employee account</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>13. </span>
                  <span>Select connect application, BambooHR</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>14. </span>
                  <span>Click on Test connection and click on Save</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>15. </span>
                  <span>Now, configure the Azure AD user flow</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>16. </span>
                  <span>Connect with Azure AD</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>17. </span>
                  <span>Update predefined Input fields if you wish</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>18. </span>
                  <span>Click on Save</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>19. </span>
                  <span>
                    19. Set the timer to allow Azure AD to fetch updates and
                    Save
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>20. </span>
                  <span>Update users now</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>21. </span>
                  <span>Connect application for Azure AD</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>22. </span>
                  <span>
                    Update users by providing details in the ‘Action Input’
                    section and save
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>23. </span>
                  <span>Assign a license to a user</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>24. </span>
                  <span>Connect to the Azure AD account</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>25. </span>
                  <span>
                    In the Action Input field, provide the license name if you
                    created it for Azure AD
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>26. </span>
                  <span>Save it</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>27. </span>
                  <span>Assign a manager for approval</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>28. </span>
                  <span>
                    Select Azure AD for manager details from the Action Input
                    field
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>29. </span>
                  <span>
                    Create a step to send an email to HR once onboarding is
                    completed
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>30. </span>
                  <span>
                    Connect with an Outlook account and provide an HR email
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>31. </span>
                  <span>Add a CC email</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>32. </span>
                  <span>Save it</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>33. </span>
                  <span>ctivate the workflow</span>
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is that easy. So, when an employee is added to BambooHR, a
                workflow trigger will be activated to complete the action of
                Azure AD provisioning and help execute the onboarding process on
                behalf of the HR executive.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Employee offboarding with Active Directory sync
              </h3>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="employee offboarding automation using BambooHR and Azure AD integration with Automate  "
              ></img>
              <p className="font-section-normal-text-testimonials line-height-18">
                The steps of implementing Azure AD integrations for employee
                offboarding are the same as employee onboarding except for two
                or three processes.
              </p>
              <ul class="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>1. </span>
                  <span>Access Automate Workflow Builder</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>2. </span>
                  <span>
                    Create a workflow by downloading it from a marketplace
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>3. </span>
                  <span>
                    Select the applications, i.e., BambooHR, Azure AD, and
                    Outlook
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>4. </span>
                  <span>Click on View Workflows</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>5. </span>
                  <span>
                    Find and click Employee Offboarding workflows, among other
                    workflows
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>6. </span>
                  <span>
                    When an employee leaves, the details are added to BambooHR,
                    and a workflow starts
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>7. </span>
                  <span>Download the workflow automation</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>8. </span>
                  <span>Add the employee details to the BambooHR account</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>9. </span>
                  <span>
                    Employee history account is checked, and the workflow will
                    be triggered
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>10. </span>
                  <span>
                    In this workflow, there are two extra steps for approval
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>11. </span>
                  <span>
                    If approved, the user license will be removed, their account
                    will be suspended, and HR or the manager will be notified
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>12. </span>
                  <span>Apply the condition for "If Approved"</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>13. </span>
                  <span>Fill 'Approval' details</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>14. </span>
                  <span>Remove the user's license</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>15. </span>
                  <span>Suspend the user account</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>16. </span>
                  <span>
                    Provide an email address to notify a particular manager or
                    HR for that specific user
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>17. </span>
                  <span>
                    If you disapprove or reject, set up your conditions by
                    removing the workflow trigger and sending a confirmation
                    email to HR or the manager
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>18. </span>
                  <span>Activate the workflow</span>
                </li>
              </ul>

              <p className="font-section-normal-text-testimonials line-height-18">
                So, your BambooHR and Azure AD onboarding workflow is ready to
                go.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Employee update in Azure AD
              </h3>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="employee update automation using BambooHR and Azure AD integration with Automate "
              ></img>

              <p className="font-section-normal-text-testimonials line-height-18">
                When a new employee status comes up in BambooHR, a new status
                can be updated in the Azure AD Identity management system using
                the Workativ Automate integration platform.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The steps are pretty much similar to the previous two workflows.
              </p>
              <ul class="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>1. </span>
                  <span>
                    Create a new workflow for employee updates in Azure AD
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>2. </span>
                  <span>
                    Choose your applications, i.e., BambooHR, Azure AD
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>3. </span>
                  <span>
                    Find "Employee Update" workflow templates among other
                    templates and choose it
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>4. </span>
                  <span>Download the workflow</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>5. </span>
                  <span>
                    Similarly, like other workflows, connect the BambooHR
                    account and save it
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>6. </span>
                  <span>
                    Modify the Azure AD account by providing details for the
                    appropriate fields
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>7. </span>
                  <span>It is all done</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>8. </span>
                  <span>Activate the workflow</span>
                </li>
              </ul>

              <h3 className="font-section-sub-header-small-home">
                Watch our tutorial video
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                To learn more about implementing successful workflows for
                employee onboarding/offboarding and employee updates,{" "}
                <a
                  href="https://www.youtube.com/watch?v=zWyg_1kVuAA"
                  target="_blank"
                >
                  watch our YouTube video now.
                </a>
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What are the benefits of BambooHR X Azure AD integrations for HR
                workflow automation with Workativ Automate?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                As you observe through the steps for workflow implementation, it
                is an absolute no-brainer.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                No coding or exerting complicated programming skills here.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ Automate is a highly useful automation platform for
                employee onboarding, onboarding, and employee updates. It does
                not unleash extra effort for your IT people to work with the
                platform and implement workflow automation. The platform
                delivers ample employee benefits to improve IT productivity and
                employee experience.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials-medium color-black">
                  Fast deployment, zero waste of productivity
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                While creating manually for Azure AD accounts per user may take
                several hours. But, with workflows being implemented with
                Workativ Automate, an action for employee onboarding/offboarding
                or user updates takes place in a few minutes.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                Organizations can have a faster deployment of workflows and
                reduce productivity loads, meaning time savings and cost
                efficiency for IT leaders.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials-medium color-black">
                  Effective employee life cycle management
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                Workativ Automate flexibly helps you manage the employee
                lifecycle by allowing you to create incidents in ITSM platforms
                and notify the right person to execute the action.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                For example, if a new employee is onboarded, an ITSM can send a
                notification for Outlook or Slack to allow the IT asset team to
                allocate appropriate resources.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials-medium color-black">
                  Reduction in ticket volumes
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                Yes, this is a huge respite for ITSM managers as they are less
                likely to spend time addressing tickets in queues for employee
                lifecycle management, cutting off the need to connect with HR
                and then employees back and forth for every update.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                All happens silently in the backend with Workativ Automate
                workflow automation.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials-medium color-black">
                  Seamless regulatory compliance
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                In many instances, employee offboarding does not complete the
                suspension of licensing in Azure AD, inadvertently allowing for
                misusing enterprise data.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                Workativ Automate helps comply with organizational laws and
                rules by seamlessly automating the deprovisioning and suspending
                of user accounts from the Azure AD.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-5 font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials-medium color-black">
                  Enhanced productivity
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-6">
                With Workativ Automate, there are no longer back-and-forth IT
                maneuvers in the Azure AD, which frees up more time for IT
                people from manual work and allows them to work more on
                strategic aspects of the business.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Employee lifecycle management is critical for every organization
                to improve operational efficiency while offering enhanced
                employee experience and facilitating organizational growth.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                This is where the HR cloud app and Azure AD integrations make it
                easy to build end-to-end employee lifecycle management
                processes.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/workflow-automation-platform/integrations/bamboohr+microsoft-azure-ad">
                  Workativ is a no-code platform,
                </a>{" "}
                and workflow implementation is just easy with this platform. It
                eliminates all the intricate manual steps for Azure AD account
                creation and helps speed up employee onboarding/offboarding and
                employee updates.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to simplify employee lifecycle management through
                BambooHR and Azure AD integrations and give your IT people less
                headache, Workativ Automate is your answer.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                To book a demo with our workflow automation expert,{" "}
                <a href="https://outlook.office365.com/owa/calendar/Bookademowithourworkflowautomationexpert@workativ.com/bookings/">
                  set up your preferred discovery call today.
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/bamboohr-chatbot-guide"
              className="font-section-normal-text-testimonials"
            >
              The Ultimate Guide to BambooHR Chatbot 
            </a>
          </li>
          <li>
            <a
              href="https://blog.workativ.com/workflow-automation-definition-how-to-and-examples-workativ/"
              className="font-section-normal-text-testimonials"
            >
              Workflow Automation | Definition, Why, Benefits and Usecases –
              Workativ
            </a>
          </li>
          <li>
            <a
              href="https://blog.workativ.com/top-9-reasons-to-build-a-digital-workplace-workativ/"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Top 9 Reasons To Build a Digital Workplace – Workativ
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
